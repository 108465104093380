<template>
    <div>
        <p class="text-h6 font-weight-bold teal--text text--darken-1">
            基本情報
        </p>
        <v-simple-table style="border:1px solid #4ACAA0">
            <template v-slot:default >
                <tbody>
                    <tr>
                        <td class="teal--text text--darken-1">建物名称</td>
                        <td colspan="5" class="black--text" style="border-left:1px solid #ccc;">{{ buildingInfo.building_name }}</td>
                    </tr>
                    <tr>
                        <td class="teal--text text--darken-1">地域の区分</td>
                        <td style="border-left:1px solid #ccc;">{{ buildingInfo.chiki }}地域</td>
                        <td class="teal--text text--darken-1" style="border-left:1px solid #ccc;">住棟全体の住戸数</td>
                        <td colspan="3" style="border-left:1px solid #ccc;">{{buildingInfo.household_total_num}}戸</td>
                    </tr>
                    <tr>
                        <td class="teal--text text--darken-1">入力方法</td>
                        <td style="border-left:1px solid #ccc;">{{ buildingInfo.evaluation_method }}</td>
                        <td class="teal--text text--darken-1" style="border-left:1px solid #ccc;">外皮性能の評価方法</td>
                        <td style="border-left:1px solid #ccc;">{{ buildingInfo.envelope_standard }}</td>
                        <td class="teal--text text--darken-1" style="border-left:1px solid #ccc;">一次エネの評価方法</td>
                        <td style="border-left:1px solid #ccc;">{{ buildingInfo.energy_standard }}</td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
        <p class="text-h6 font-weight-bold teal--text text--darken-1 pt-10">
            外皮性能
        </p>
        <v-simple-table style="border:1px solid #4ACAA0">
            <template v-slot:default >
                <tbody>
                    <tr>
                        <td rowspan="2" class="teal--text text--darken-1" style="width:15%">住戸単位</td>
                        <td class="teal--text text--darken-1" style="border-left:1px solid #ccc; width:15%">外皮基準適合戸数</td>
                        <td colspan="2" class="black--text" style="border-left:1px solid #ccc;">{{ envelopePerformance.adaptable_household_total_num == '-' ? '-' : envelopePerformance.adaptable_household_total_num + '戸' }}</td>
                    </tr>
                    <tr>
                        <td class="teal--text text--darken-1" style="border-left:1px solid #ccc;">基準値</td>
                        <td class="black--text" style="border-left:1px solid #ccc;  width:25%">U<sub>A</sub> = {{ envelopePerformance.household_standard_ua }}</td>
                        <td class="black--text" style="border-left:1px solid #ccc;">&#951;<sub>AC</sub> = {{ envelopePerformance.household_standard_eta_ac }}</td>
                    </tr>
                    <tr>
                        <td rowspan="2" class="teal--text text--darken-1" style="border-bottom:none">住棟単位平均</td>
                        <td class="teal--text text--darken-1" style="border-left:1px solid #ccc;">設計値</td>
                        <td class="black--text" style="border-left:1px solid #ccc;">U<sub>A</sub> = {{ envelopePerformance.building_ua }}</td>
                        <td class="black--text" style="border-left:1px solid #ccc;">&#951;<sub>AC</sub> = {{ envelopePerformance.building_eta_ac }}</td>
                    </tr>
                    <tr>
                        <td class="teal--text text--darken-1" style="border-left:1px solid #ccc;">基準値</td>
                        <td class="black--text" style="border-left:1px solid #ccc;">U<sub>A</sub> = {{ envelopePerformance.building_standard_ua }}</td>
                        <td class="black--text" style="border-left:1px solid #ccc;">&#951;<sub>AC</sub> = {{ envelopePerformance.building_standard_eta_ac }}</td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
        <p class="text-h6 font-weight-bold teal--text text--darken-1 pt-10">
            住宅部分の一次エネルギー消費量
        </p>
        <v-simple-table style="border:1px solid #4ACAA0">
            <template v-slot:default >
                <tbody class="text-center">
                    <tr>
                        <td></td>
                        <td>
                            <span class="teal--text text--darken-1 d-block">
                                設計一次<br/>エネルギー消費量
                            </span>
                            [ GJ/年 ]
                        </td>
                        <td style="border-left:1px solid #ccc;">
                            <span class="teal--text text--darken-1 d-block">
                                基準一次<br/>エネルギー消費量
                            </span>
                            [ GJ/年 ]
                        </td>
                        <td style="border-left:1px solid #ccc;">
                            <span class="teal--text text--darken-1 d-block">
                                設計一次<br/>エネルギー消費量<br/>(その他除く)
                            </span>
                            [ GJ/年 ]
                        </td>
                        <td style="border-left:1px solid #ccc;">
                            <span class="teal--text text--darken-1 d-block">
                                基準一次<br/>エネルギー消費量<br/>(その他除く)
                            </span>
                            [ GJ/年 ]
                        </td>
                        <td class="teal--text text--darken-1" style="border-left:1px solid #ccc;">BEI</td>
                    </tr>
                    <tr>
                        <td class="teal--text text--darken-1 text-left">住戸部分</td>
                        <td>{{ householdEnergyConsumption.e_t }}</td>
                        <td style="border-left:1px solid #ccc;">{{ householdEnergyConsumption.e_st }}</td>
                        <td style="border-left:1px solid #ccc;">{{ householdEnergyConsumption.e_t_without_e_m }}</td>
                        <td style="border-left:1px solid #ccc;">{{ householdEnergyConsumption.e_st_without_e_sm }}</td>
                        <td style="border-left:1px solid #ccc;">{{ householdEnergyConsumption.bei }}</td>
                    </tr>
                    <tr>
                        <td class="teal--text text--darken-1 text-left">共用部分</td>
                        <td>{{ (commonAreaType) ? commonEnergyConsumption.e_t : "0.0" }}</td>
                        <td style="border-left:1px solid #ccc;">{{ (commonAreaType) ? commonEnergyConsumption.e_st : "0.0" }}</td>
                        <td style="border-left:1px solid #ccc;">{{ (commonAreaType) ? commonEnergyConsumption.e_t_without_e_m : "0.0" }}</td>
                        <td style="border-left:1px solid #ccc;">{{ (commonAreaType) ? commonEnergyConsumption.e_st_without_e_sm : "0.0" }}</td>
                        <td style="border-left:1px solid #ccc;">{{ (commonAreaType) ? commonEnergyConsumption.bei : "0.0" }}</td>
                    </tr>
                    <tr class="font-weight-bold">
                        <td class="text-right teal--text text--darken-1">合計</td>
                        <td>{{ totalEnergyConsumption.e_t }}</td>
                        <td style="border-left:1px solid #ccc;">{{ totalEnergyConsumption.e_st }}</td>
                        <td style="border-left:1px solid #ccc;">{{ totalEnergyConsumption.e_t_without_e_m }}</td>
                        <td style="border-left:1px solid #ccc;">{{ totalEnergyConsumption.e_st_without_e_sm }}</td>
                        <td style="border-left:1px solid #ccc;">{{ totalEnergyConsumption.bei }}</td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
        <v-row class="text-h6 font-weight-bold teal--text text--darken-1 pt-10 pb-5 pl-3 pr-3">
            単位住戸の情報
            <v-spacer></v-spacer>
            {{ buildingInfo.household_total_num }}件
        </v-row>
        <v-data-table
            style="border:1px solid #4ACAA0"
            class="tbl"
            :items="householdResultsTable.household_results_table"
            :footer-props="{
                showFirstLastPage: true,
                itemsPerPageOptions : [50,100],
                itemsPerPageText : ''
            }"
        >
            <template v-slot:body="props">
                <tbody class="text-center">
                    <tr class="teal--text text--darken-1">
                        <td rowspan="2">住戸の番号</td>
                        <td rowspan="2">住宅タイプの名称</td>
                        <td rowspan="2">住戸の存する階</td>
                        <td rowspan="2">
                            床面積の合計
                            <span class="black--text d-block">[m<sup>2</sup>]</span>
                        </td>
                        <td colspan="3" style="border-left:1px solid #ccc;">外皮性能</td>
                        <td colspan="5" style="border-left:1px solid #ccc;">一次エネルギー消費性能</td>
                    </tr>
                    <tr class="teal--text text--darken-1">
                        <td style="border-left:1px solid #ccc;">
                            外皮平均<br/>熱貫流率<br/>(U<sub>A</sub>)
                            <span class="black--text d-block">[W/m<sup>2</sup>K]</span>
                        </td>
                         <td>
                            冷房期平均<br/>日射熱取得率<br/>(&#951;<sub>AC</sub>)
                        </td>
                        <td>判定</td>
                        <td style="border-left:1px solid #ccc;">
                            設計一次<br/>エネルギー<br/>消費量
                            <span class="black--text d-block">[GJ]</span>
                        </td>
                        <td>
                            基準一次<br/>エネルギー<br/>消費量
                            <span class="black--text d-block">[GJ]</span>
                        </td>
                        <td>
                            設計一次<br/>エネルギー<br/>消費量<br/>(その他除く)
                            <span class="black--text d-block">[GJ]</span>
                        </td>
                        <td>
                            基準一次<br/>エネルギー<br/>消費量<br/>(その他除く)
                            <span class="black--text d-block">[GJ]</span>
                        </td>
                        <td>
                            BEI
                        </td>
                    </tr>

                    <tr v-for="(item , index) in props.items" :key="index">
                        <td class="teal--text text--darken-1 text-left font-weight-bold">
                            {{ item.household_num }}
                        </td>
                        <td>{{ item.household_type }}</td>
                        <td>{{ item.floor }}</td>
                        <td>{{ item.total_area }}</td>
                        <td style="border-left:1px solid #ccc;">{{ item.ua }}</td>
                        <td>{{ item.eta_ac }}</td>
                        <td>{{ item.status_gn_p }}</td>
                        <td style="border-left:1px solid #ccc;">{{ item.e_t }}</td>
                        <td>{{ item.e_st }}</td>
                        <td>{{ item.e_t_without_e_m }}</td>
                        <td>{{ item.e_st_without_e_sm }}</td>
                        <td>{{ item.bei }}</td>
                    </tr>
                </tbody>
            </template>
        </v-data-table>

        <div v-if='basicInfo.evaluation_method != 1'>
            <v-divider color="#4ACAA0" class="mt-16"></v-divider>

            <p class="text-h6 font-weight-bold grey--text pt-10">
                以下参考
            </p>
            <v-simple-table style="border:1px solid grey; color:grey;" class="tbl uq_cfTable">
                <template v-slot:default >
                    <tbody class="text-center">
                        <tr class="teal--text">
                            <td rowspan="2"></td>
                            <td rowspan="2">住戸の番号</td>
                            <td rowspan="2">住戸の存する階</td>
                            <td rowspan="2">
                                床面積の合計
                                <span class="grey--text d-block">[m<sup>2</sup>]</span>
                            </td>
                            <td colspan="2" style="border-left:1px solid #ccc; color:grey;">外皮性能</td>
                            <td colspan="5" style="border-left:1px solid #ccc; color:grey;">一次エネルギー消費性能</td>
                        </tr>
                        <tr class="teal--text">
                            <td style="border-left:1px solid #ccc;">
                                外皮平均<br/>熱貫流率<br/>(U<sub>A</sub>)
                                <span class="grey--text d-block">[W/m<sup>2</sup>K]</span>
                            </td>
                            <td>
                                冷房期平均<br/>日射熱取得率<br/>(&#951;<sub>AC</sub>)
                            </td>
                            <td style="border-left:1px solid #ccc;">
                                設計一次<br/>エネルギー<br/>消費量
                                <span class="grey--text d-block">[GJ]</span>
                            </td>
                            <td>
                                基準一次<br/>エネルギー<br/>消費量
                                <span class="grey--text d-block">[GJ]</span>
                            </td>
                            <td>
                                設計一次<br/>エネルギー<br/>消費量<br/>(その他除く)
                                <span class="grey--text d-block">[GJ]</span>
                            </td>
                            <td>
                                基準一次<br/>エネルギー<br/>消費量<br/>(その他除く)
                                <span class="grey--text d-block">[GJ]</span>
                            </td>
                            <td>
                                BEI
                            </td>
                        </tr>
                        <tr>
                            <td class="text-left font-weight-bold">
                                BEI代表住戸
                            </td>
                            <td>{{ beiInfo.bei_rep_household.household_num }}</td>
                            <td>{{ beiInfo.bei_rep_household.floor }}</td>
                            <td>{{ beiInfo.bei_rep_household.total_area }}</td>
                            <td style="border-left:1px solid #ccc;">{{ beiInfo.bei_rep_household.ua }}</td>
                            <td>{{ beiInfo.bei_rep_household.eta_ac }}</td>
                            <td style="border-left:1px solid #ccc;">{{ beiInfo.bei_rep_household.e_t }}</td>
                            <td>{{ beiInfo.bei_rep_household.e_st }}</td>
                            <td>{{ beiInfo.bei_rep_household.e_t_without_e_m }}</td>
                            <td>{{ beiInfo.bei_rep_household.e_st_without_e_sm }}</td>
                            <td>{{ beiInfo.bei_rep_household.bei }}</td>
                        </tr>
                        <tr>
                            <td class="text-left font-weight-bold">
                                BEI最大住戸
                            </td>
                            <td>{{ beiInfo.bei_max_household.household_num }}</td>
                            <td>{{ beiInfo.bei_max_household.floor }}</td>
                            <td>{{ beiInfo.bei_max_household.total_area }}</td>
                            <td style="border-left:1px solid #ccc;">{{ beiInfo.bei_max_household.ua }}</td>
                            <td>{{ beiInfo.bei_max_household.eta_ac }}</td>
                            <td style="border-left:1px solid #ccc;">{{ beiInfo.bei_max_household.e_t }}</td>
                            <td>{{ beiInfo.bei_max_household.e_st }}</td>
                            <td>{{ beiInfo.bei_max_household.e_t_without_e_m }}</td>
                            <td>{{ beiInfo.bei_max_household.e_st_without_e_sm }}</td>
                            <td>{{ beiInfo.bei_max_household.bei }}</td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
            <p class="pt-2">
                BEI代表住戸:BEIが全住戸の中間に位置する住戸(建築物の住戸の数が偶数の場合はBEIの小さい(省エネ性能の良い)方)<br/>
                BEI代表住戸が複数ある場合はU<sub>A</sub>が中間値 → &#951;<sub>AC</sub> が中間値 → 床面積が最大値 → 住戸番号が小さい(一番上の行)の順に判断
            </p>
            <p>
                BEI最大住戸:BEIが全住戸の中で最大の住戸<br/>
                BEI最大住戸が複数ある場合はU<sub>A</sub>が最大値 → &#951;<sub>AC</sub> が最大値 → 床面積が最大値 → 住戸番号が小さい(一番上の行)の順に判断
            </p>
        </div>
        <v-row class="pl-5 pr-5">
            <v-btn
                to="common-area-upload"
                rounded-lg
                color="#A0A0A0"
                width="150"
                height="60"
                class="mt-15 white--text font-weight-bold text-h6"
            >
                <v-icon large>mdi-chevron-left</v-icon>
                <v-spacer></v-spacer>
                 戻る
                <v-spacer></v-spacer>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
                to="pdf-generate"
                color="#025F59"
                width="200"
                height="60"
                class="mt-15 ml-5 white--text font-weight-bold text-h6"
            >
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                 PDF生成
                <v-spacer></v-spacer>
                <v-icon large>mdi-chevron-right</v-icon>
            </v-btn>
        </v-row>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
    computed: {
        ...mapGetters('basicInfo', {
            basicInfo: 'getBasicInfo',
            commonAreaType: 'getCommonAreaType'
            }),
        ...mapGetters('result', {
            buildingInfo: 'getBuildingInfo',
            householdResultsTable: 'getHouseholdResultsTable',
            envelopePerformance: 'getEnvelopePerformance',
            householdEnergyConsumption: 'getHouseholdEnergyConsumption',
            commonEnergyConsumption: 'getCommonEnergyConsumption',
            totalEnergyConsumption: 'getTotalEnergyConsumption',
            beiInfo: 'getBeiInfo'
        }),
    }
}
</script>
<style scoped>
.tbl td {
  padding: 0 8px !important;
}
.uq_cfTable th,
.uq_cfTable td{
    color: grey;
}
</style>